/* eslint-disable object-curly-newline */
import { Entity } from '@inputs/entity.ts';

const defaultProvider = {

    team: new Entity({ fieldNameId: 'ID_EQUIPE', descriptionField: 'DESC_EQUIPE', commandName: 'ConsultarEquipesVersionadas', providerField: 'EQUIPES.EQUIPE', searchField: 'equipes' }),
    modelEvent: new Entity({ fieldNameId: 'ID_MODELO_EVENTO', descriptionField: 'DESC_MODELO_EVENTO', commandName: 'ConsultarModeloEventoPorPalavra', providerField: 'MODELOS_EVENTO.MODELO_EVENTO', searchField: 'modelos' }),
    workOrder: new Entity({ fieldNameId: 'ID_ORDEM_SERVICO', descriptionField: 'DESC_ORDEM_SERVICO', commandName: 'ConsultarOrdemServicoEvento', providerField: 'ORDENS.ORDEM', searchField: 'ordens' }),
    contributor: new Entity({ fieldNameId: 'ID_COLABORADOR', descriptionField: 'NOME_COMPLETO', commandName: 'ConsultarColaboradoresPorPalavra', providerField: 'COLABORADORES.COLABORADOR', searchField: 'PALAVRA', minToSearch: 2 }),
    permissionGroups: new Entity({ fieldNameId: 'ID_GRUPO_PERMISSOES', descriptionField: 'NOME_GRUPO_PERMISSOES', commandName: 'ConsultarGruposPermissoes', providerField: 'GRUPOS_PERMISSOES.GRUPO_PERMISSOES', searchField: 'BUSCA', minToSearch: 2 }),
    companies: new Entity({ fieldNameId: 'ID_PESSOA', descriptionField: 'NOME', commandName: 'ConsultarEmpresasUsuarias', providerField: 'EMPRESAS_USUARIAS.EMPRESA_USUARIA', searchField: 'empresa' }),
    dailyWorkReportTemplate: new Entity({ fieldNameId: 'ID_DIARIO_TRABALHO_TEMPLATE_RELATORIO', descriptionField: 'NOME', commandName: 'ConsultarTemplatesRelatorioDiarioObra', providerField: 'TEMPLATES_RELATORIO.TEMPLATE_RELATORIO', searchField: {} }),
    worksite: new Entity({ fieldNameId: 'ID_PARQUE_SERVICO', descriptionField: 'NOME_PARQUE_SERVICO', commandName: 'ConsultarParquesServico', providerField: 'PARQUES_SERVICO.PARQUE_SERVICO', saveUserSelectionKey: 'worksiteId', params: { ATIVO: 1 } }),
    attributeSchema: new Entity({ fieldNameId: 'ID_ESQUEMA_ATRIBUTOS', descriptionField: 'DESC_ESQUEMA_ATRIBUTOS', commandName: 'ConsultarEsquemas', providerField: 'ESQUEMAS.ESQUEMA' }),
    attribute: new Entity({ fieldNameId: 'ID_ATRIBUTO', descriptionField: 'NOME', commandName: 'ConsultarAtributos', providerField: 'ATRIBUTOS.ATRIBUTO', searchField: 'PALAVRA', minToSearch: 2 }),
    attributeByAssetType: new Entity({ fieldNameId: 'ID_ATRIBUTO', descriptionField: 'NOME', commandName: 'ConsultarAtributosPorTipoPontoServico', providerField: 'ATRIBUTOS_TIPO_PONTO_SERVICO.ATRIBUTO_TIPO_PONTO_SERVICO' }),
    attributeHierarchyByAssetType: new Entity({ fieldNameId: 'ID_ATRIBUTO', descriptionField: 'NOME', commandName: 'ConsultarHierarquiaAtributosPorTipoPontoServico', providerField: 'ATRIBUTOS_TIPO_PONTO_SERVICO.ATRIBUTO_TIPO_PONTO_SERVICO' }),
    attributeByIssueType: new Entity({ fieldNameId: 'ID_ATRIBUTO', descriptionField: 'NOME', commandName: 'ConsultarAtributosPorTipoOcorrencia', providerField: 'ATRIBUTOS.ATRIBUTO' }),
    attributeByMaintenanceReason: new Entity({ fieldNameId: 'ID_ATRIBUTO', descriptionField: 'NOME', commandName: 'ConsultarAtributosPorMotivoAtendimento', providerField: 'ATRIBUTOS.ATRIBUTO' }),
    attributeValue: new Entity({ fieldNameId: 'ID_ATRIBUTO_VALOR', descriptionField: 'DESC_ATRIBUTO_VALOR', commandName: 'ConsultarAtributoValores', providerField: 'ATRIBUTO_VALORES.ATRIBUTO_VALOR' }),
    state: new Entity({ fieldNameId: 'SIGLA_UF', descriptionField: 'SIGLA_UF', commandName: 'ConsultarEstados', providerField: 'ESTADOS.ESTADO', params: { FILTRO_PAIS_INSTANCIA: false } }),
    region: new Entity({ fieldNameId: 'ID_REGIAO', descriptionField: 'NOME_REGIAO', commandName: 'ConsultarRegioes', providerField: 'REGIOES.REGIAO', editDialog: { page: { name: 'RegionRegistration' }, pageConfig: { isDialog: true }, params: { ID_REGIAO: '_regionId' } } }),
    user: new Entity({ fieldNameId: 'ID_USUARIO', descriptionField: 'NOME', commandName: 'ConsultarUsuarioPorPalavra', providerField: 'USUARIOS.USUARIO', searchField: 'PALAVRA', minToSearch: 2 }),
    lightingAssets: new Entity({ fieldNameId: 'ID_MODAL_PONTO_LUMINOSO', descriptionField: 'DESC_MODAL_PONTO_LUMINOSO', commandName: 'ConsultarModalPontoLuminoso', providerField: 'MODALS_PONTO_LUMINOSO.MODAL_PONTO_LUMINOSO' }),
    companySite: new Entity({ fieldNameId: 'ID_LOCAL_EMPRESA', descriptionField: 'DESC_LOCAL_EMPRESA', commandName: 'ConsultarLocaisEmpresa', providerField: 'LOCAIS_EMPRESA.LOCAL_EMPRESA' }),
    companySiteFilter: new Entity({ fieldNameId: 'ID_LOCAL_EMPRESA', descriptionField: 'DESC_LOCAL_EMPRESA', commandName: 'ConsultarLocaisEmpresa', providerField: 'LOCAIS_EMPRESA.LOCAL_EMPRESA', params: { FILTRO_ROTEIRIZACAO: 1 }, editDialog: { page: { name: 'CompanyLocation' }, pageConfig: { isDialog: true, dialog: { fullscreen: false } }, params: { ID_LOCAL_EMPRESA: '_companyLocationId' }, minToSearch: 0 } }),
    companySiteType: new Entity({ fieldNameId: 'ID_TIPO_LOCAL_EMPRESA', descriptionField: 'DESC_TIPO_LOCAL_EMPRESA', commandName: 'ConsultarTiposLocalEmpresa', providerField: 'TIPOS.TIPO' }),
    permissionGroup: new Entity({ fieldNameId: 'ID_GRUPO_PERMISSOES', descriptionField: 'NOME_GRUPO_PERMISSOES', commandName: 'ConsultarGruposPorPalavra', providerField: 'GRUPOS.GRUPO', searchField: 'PALAVRA', params: { SOMENTE_GRUPOS: 0 } }),
    clientContract: new Entity({ fieldNameId: 'ID_CONTRATO', descriptionField: 'LABEL', commandName: 'ConsultarContratosClientePorPalavra', providerField: 'CONTRATOS.CONTRATO' }),
    contract: new Entity({ fieldNameId: 'ID_CONTRATO', descriptionField: 'DESC_ORCAMENTO', commandName: 'ConsultarContratos', providerField: 'CONTRATOS.CONTRATO' }),
    financialCenter: new Entity({ fieldNameId: 'ID_CENTRO_FINANC', descriptionField: 'COD_CENTRO_FINANC', commandName: 'ConsultarCentroFinancPaiPorPalavra', providerField: 'CENTROS_FINANC.CENTRO_FINANC', searchField: 'PALAVRA', params: { PERMITE_MOVIMENTOS: -1, AGRUPADOR: -1, CF_APENAS_ATIVOS: 1 }, minToSearch: 2 }),
    stockType: new Entity({ fieldNameId: 'SIGLA_TIPO_ORIG_DEST_ESTOQUE', descriptionField: 'DESC_TIPO_ORIG_DEST_ESTOQUE', commandName: 'ConsultarTiposOrigDestEstoque', providerField: 'TIPOS_ORIG_DEST_ESTOQUE.TIPO_ORIG_DEST_ESTOQUE' }),
    locationGrouping: new Entity({ fieldNameId: 'ID_AGRUPAMENTO_LOCALIZACAO', descriptionField: 'DESC_AGRUPAMENTO_LOCALIZACAO', commandName: 'ConsultarAgrupamentoLocalizacao', providerField: 'AGRUPAMENTO_LOCALIZACOES.AGRUPAMENTO_LOCALIZACAO' }),
    issueType: new Entity({ fieldNameId: 'ID_TIPO_OCORRENCIA', descriptionField: 'DESC_TIPO_OCORRENCIA', commandName: 'ConsultarTipoOcorrencia', providerField: 'TIPOS_OCORRENCIA.TIPO_OCORRENCIA', params: { SOMENTE_PREVENTIVAS: 0, HABILITADO_PARQUE: 1, SOMENTE_ATRASADAS: 0, SOMENTE_NAO_PREVENTIVAS: 0, HABILITADO_CALLCENTER: 0 } }),
    issueTypeWithoutFilters: new Entity({ fieldNameId: 'ID_TIPO_OCORRENCIA', descriptionField: 'DESC_TIPO_OCORRENCIA', commandName: 'ConsultarTipoOcorrencia', providerField: 'TIPOS_OCORRENCIA.TIPO_OCORRENCIA' }),
    assetIssuePriority: new Entity({ fieldNameId: 'SIGLA_PRIORIDADE_PONTO_OCORR', descriptionField: 'DESC_PRIORIDADE_PONTO_OCORR', commandName: 'ConsultarPrioridadesPontoOcorrencia', providerField: 'PRIORIDADES_PONTO_OCORRENCIA.PRIORIDADE_PONTO_OCORRENCIA' }),
    holidayCalendar: new Entity({ fieldNameId: 'ID_CALENDARIO_FERIADOS', descriptionField: 'DESC_CALENDARIO_FERIADOS', commandName: 'ConsultarCalendariosFeriados', providerField: 'CALENDARIOS.CALENDARIO' }),
    stockCenter: new Entity({ fieldNameId: 'ID_CENTRO_ESTOQUE', descriptionField: 'DESC_CENTRO_ESTOQUE', commandName: 'ConsultarCentrosEstoque', providerField: 'CENTROS_ESTOQUE.CENTRO_ESTOQUE' }),
    listing: new Entity({ fieldNameId: 'ID_LISTAGEM', descriptionField: 'DESC_LISTAGEM', commandName: 'ConsultarListagensSimples', providerField: 'LISTAGENS.LISTAGEM' }),
    tariffPlan: new Entity({ fieldNameId: 'ID_PLANO_TARIFA_ENERGIA', descriptionField: 'DESC_PLANO_TARIFA_ENERGIA', commandName: 'ConsultarPlanosTarifariosEnergia', providerField: 'PLANOS.PLANO' }),
    flag: new Entity({ fieldNameId: 'ID_BANDEIRA_PLANO_TARIFARIO', descriptionField: 'DESC_BANDEIRA_PLANO_TARIFARIO', commandName: 'ConsultarBandeiraPlanoTarifario', providerField: 'BANDEIRAS_PLANOS_TARIFARIOS.BANDEIRA_PLANO_TARIFARIO' }),
    workOrderStatus: new Entity({ fieldNameId: 'ID_STATUS_ORDEM_SERVICO', descriptionField: 'DESC_STATUS_ORDEM_SERVICO', commandName: 'ConsultarStatusOrdemServico', providerField: 'ORDENS_SERVICO.ORDEM_SERVICO' }),
    workOrderType: new Entity({ fieldNameId: 'ID_TIPO_ORDEM_SERVICO', descriptionField: 'DESC_TIPO_ORDEM_SERVICO', commandName: 'ConsultarTipoOrdemServico', providerField: 'ORDENS_SERVICO.ORDEM_SERVICO' }),
    assistanceStatus: new Entity({ fieldNameId: 'ID_STATUS_ATENDIMENTO_PS', descriptionField: 'DESC_STATUS_ATENDIMENTO_PS', commandName: 'ConsultarStatusAtendimentoPS', providerField: 'STATUS_ATENDIMENTO_PS.STATUS' }),
    assitanceMotive: new Entity({ fieldNameId: 'ID_MOTIVO_ATENDIMENTO_PS', descriptionField: 'DESC_MOTIVO_ATENDIMENTO_PS', commandName: 'ConsultarMotivosAtendimentoPS', providerField: 'MOTIVOS_ATENDIMENTO.MOTIVO_ATENDIMENTO' }),
    assitanceSolution: new Entity({ fieldNameId: 'ID_SOLUCAO_ATENDIMENTO_PS', descriptionField: 'DESC_SOLUCAO_ATENDIMENTO_PS', commandName: 'ConsultarSolucoesAtendimentoPS', providerField: 'SOLUCOES_ATENDIMENTO.SOLUCAO_ATENDIMENTO' }),
    assetType: new Entity({ fieldNameId: 'ID_TIPO_PONTO_SERVICO', descriptionField: 'DESC_TIPO_PONTO_SERVICO', commandName: 'TiposPontosServicos', providerField: 'TIPOS_PS.TIPO_PS' }),
    itemCondition: new Entity({ fieldNameId: 'ID_ESTADO_ITEM_PONTO_SERVICO', descriptionField: 'DESC_ESTADO_ITEM_PONTO_SERVICO', commandName: 'ConsultarEstadosPontoServico', providerField: 'ESTADOS_PS.ESTADO_PS' }),
    ticketAlertType: new Entity({ fieldNameId: 'ID_TIPO_ALERTA_SOLICITACAO', descriptionField: 'DESC_TIPO_ALERTA_SOLICITACAO', commandName: 'ConsultarTipoAlertaSolicitacao', providerField: 'TIPOS_ALERTAS_SOLICITACAOS.TIPO_ALERTA_SOLICITACAO' }),
    modelDocument: new Entity({ fieldNameId: 'ID_MODELO_DOCUMENTO', descriptionField: 'DESC_MODELO_DOCUMENTO', commandName: 'ConsultarModeloDocumento', providerField: 'MODELOS_DOCUMENTO.MODELO_DOCUMENTO', searchField: 'modelos' }),
    userCompany: new Entity({ fieldNameId: 'ID_PESSOA', descriptionField: 'NOME', commandName: 'ConsultarEmpresasUsuariasPorPalavra', providerField: 'PESSOAS.PESSOA', searchField: 'PALAVRA', editDialog: { page: { name: 'UserCompany' }, pageConfig: { isDialog: true, dialog: { fullscreen: false } }, params: { ID_PESSOA: '_userCompanyId' } }, minToSearch: 2 }),
    allUserCompany: new Entity({ fieldNameId: 'ID_PESSOA', descriptionField: 'NOME', commandName: 'ConsultarEmpresasUsuarias', providerField: 'EMPRESAS_USUARIAS.EMPRESA_USUARIA', searchField: 'PALAVRA' }),
    area: new Entity({ fieldNameId: 'ID_AREA', descriptionField: 'DESCRICAO', commandName: 'ConsultarAreaPorPalavra', providerField: 'AREAS.AREA', searchField: 'PALAVRA' }),
    project: new Entity({ fieldNameId: 'ID_OBRA', descriptionField: 'DESC_OBRA', commandName: 'ConsultarObrasPorPalavra', providerField: 'OBRAS.OBRA', searchField: 'PALAVRA', params: { PAGE_SIZE: 300 }, minToSearch: 2 }),
    itemType: new Entity({ fieldNameId: 'ID_TIPO_ITEM', descriptionField: 'DESC_TIPO_ITEM', commandName: 'ConsultaTipoItemSimples', providerField: 'TIPOS_ITENS.TIPO_ITEM', searchField: {} }),
    clients: new Entity({ fieldNameId: 'ID_CLIENTE', descriptionField: 'NOME', commandName: 'ConsultarClientesPorPalavra', providerField: 'CLIENTES.CLIENTE', searchField: 'PALAVRA', editDialog: { page: { name: 'Person' }, pageConfig: { isDialog: true, dialog: { fullscreen: false } }, params: { ID_CLIENTE: '_personId' }, minToSearch: 2 } }),
    families: new Entity({ fieldNameId: 'ID_FAMILIA', descriptionField: 'COD_FAMILIA', commandName: 'ConsultarFamilias', providerField: 'FAMILIAS.FAMILIA', searchField: 'PALAVRA', minToSearch: 2 }),
    interventionFamilies: new Entity({ fieldNameId: 'ID_FAMILIA', descriptionField: 'COD_FAMILIA', commandName: 'ConsultarFamiliasPorPalavra', providerField: 'FAMILIAS.FAMILIA', searchField: 'PALAVRA', minToSearch: 2, params: { CONSUMO: 0 } }),
    person: new Entity({ fieldNameId: 'ID_PESSOA', descriptionField: 'NOME', commandName: 'ConsultarPessoasPorPalavra', providerField: 'PESSOAS.PESSOA', searchField: 'PALAVRA', params: { SOMENTE_ATIVOS: 0, SOMENTE_CLIENTES: 0 }, minToSearch: 2 }),
    companyLocation: new Entity({ fieldNameId: 'ID_LOCAL_EMPRESA', descriptionField: 'DESC_LOCAL_EMPRESA', commandName: 'ConsultarLocalEmpresaPorPalavra', providerField: 'LOCAIS_EMPRESAS.LOCAL_EMPRESA', searchField: 'PALAVRA' }),
    district: new Entity({ fieldNameId: 'ID_BAIRRO', descriptionField: 'NOME_BAIRRO', commandName: 'ConsultarBairroPorPalavra', providerField: 'BAIRROS.BAIRRO', searchField: 'PALAVRA', minToSearch: 2 }),
    bankAccount: new Entity({ fieldNameId: 'NUM_BANCO', descriptionField: 'TEXTO_BANCO', commandName: 'ConsultarBancosPorPalavra', providerField: 'BANCOS.BANCO', searchField: 'PALAVRA' }),
    trafficViolation: new Entity({ fieldNameId: 'COD_INFRACAO', descriptionField: 'COD_DESC_INFRACAO', commandName: 'ConsultarInfracoesTransitoPorPalavra', providerField: 'INFRACOES.INFRACAO', searchField: 'PALAVRA' }),
    provider: new Entity({ fieldNameId: 'ID', descriptionField: 'NOME', commandName: 'ConsultarFornecedores', providerField: 'FORNECEDORES.FORNECEDOR', searchField: 'PALAVRA' }),
    familiesCOD: new Entity({ fieldNameId: 'ID_FAMILIA', descriptionField: 'COD_FAMILIA', commandName: 'ConsultarFamilias', providerField: 'FAMILIAS.FAMILIA', searchField: 'PALAVRA', editDialog: { page: { name: 'Family' }, pageConfig: { isDialog: true }, params: { ID_FAMILIA: '_familyId' } }, minToSearch: 2 }),
    ncms: new Entity({ fieldNameId: 'COD_NCM', descriptionField: 'DESCRICAO', commandName: 'ConsultarNcmsPorPalavra', providerField: 'NCMS.NCM', searchField: 'PALAVRA' }),
    genreItem: new Entity({ fieldNameId: 'COD_GENERO_ITEM', descriptionField: 'DESCRICAO_GENERO', commandName: 'ConsultarGenerosPorPalavra', providerField: 'GENEROS.GENERO', searchField: 'PALAVRA' }),
    lstService: new Entity({ fieldNameId: 'COD_LST_SERVICOS', descriptionField: 'DESCRICAO_LST_SERVICOS', commandName: 'ConsultarLstServicosPorPalavra', providerField: 'LST_SERVICOS.LST_SERVICOS', searchField: 'PALAVRA' }),
    anpFuel: new Entity({ fieldNameId: 'ID_COMBUSTIVEL_ANP', descriptionField: 'DESCRICAO_COMBUSTIVEL_ANP', commandName: 'ConsultarCombustivelANPPorPalavra', providerField: 'COMBUSTIVEL_ANPS.COMBUSTIVEL_ANP', searchField: 'PALAVRA' }),
    workSimplify: new Entity({ fieldNameId: 'ID_OBRA', descriptionField: 'DESC_OBRA', commandName: 'ConsultarObrasSimplificada', providerField: 'OBRAS.OBRA', searchField: 'PALAVRA_CHAVE', minToSearch: 2 }),
    teamSimplify: new Entity({ fieldNameId: 'ID_EQUIPE', descriptionField: 'DESC_EQUIPE', commandName: 'ConsultarEquipesSimplificada', providerField: 'EQUIPES.EQUIPE', searchField: 'BUSCA' }),
    nonConsumableMaterials: new Entity({ fieldNameId: 'ID_ITEM', descriptionField: 'DESC_ITEM', commandName: 'ConsultarMateriaisConsumo', providerField: 'MATERIAIS.MATERIAL', searchField: 'BUSCA', minToSearch: 2 }),
    serviceType: new Entity({ fieldNameId: 'ID_TIPO_SERVICO', descriptionField: 'DESCRICAO', commandName: 'ConsultarTipoServicoMVRP', providerField: 'TIPOS_SERVICOS.TIPO_SERVICO', searchField: 'PALAVRA', editDialog: { page: { name: 'ServiceType' }, pageConfig: { isDialog: true, dialog: { fullscreen: false } }, params: { ID_TIPO_SERVICO: '_serviceTypeId' } } }),
    teamWithScheduleTime: new Entity({ fieldNameId: 'ID_EQUIPE', descriptionField: 'DESC_EQUIPE', commandName: 'ConsultarEquipesComGradeHoraria', providerField: 'EQUIPES.EQUIPE', searchField: 'BUSCA' }),
    item: new Entity({ fieldNameId: 'ID_ITEM', descriptionField: 'DESC_ITEM', commandName: 'ConsultarItensPorPalavra', providerField: 'ITENS.ITEM', searchField: 'PALAVRA', minToSearch: 2 }),
    financialCategory: new Entity({ fieldNameId: 'COD_CATEGORIA', descriptionField: 'COD_CATEGORIA', commandName: 'ConsultarCategoriaFinanceiraPaiPorPalavra', providerField: 'CATEGORIAS.CATEGORIA', searchField: 'PALAVRA', minToSearch: 2 }),
    financialCenterCode: new Entity({ fieldNameId: 'COD_CENTRO_FINANC', descriptionField: 'COD_CENTRO_FINANC', commandName: 'ConsultarCentroFinancPaiPorPalavra', providerField: 'CENTROS_FINANC.CENTRO_FINANC', searchField: 'PALAVRA', minToSearch: 2 }),
    financialCenterId: new Entity({ fieldNameId: 'ID_CENTRO_FINANC', descriptionField: 'COD_CENTRO_FINANC', commandName: 'ConsultarCentroFinancPaiPorPalavra', providerField: 'CENTROS_FINANC.CENTRO_FINANC', searchField: 'PALAVRA', params: { AGRUPADOR: -1, PERMITE_MOVIMENTOS: -1, CF_APENAS_ATIVOS: 1 }, minToSearch: 2 }),
    command: new Entity({ fieldNameId: 'NOME_COMANDO', descriptionField: 'NOME_COMANDO', commandName: 'ConsultarComandosPorPalavra', providerField: 'COMANDOS.COMANDO', searchField: 'PALAVRA', minToSearch: 2 }),
    groupStatus: new Entity({ fieldNameId: 'ID_GRUPO_STATUS', descriptionField: 'DESC_GRUPO_STATUS', commandName: 'ConsultarGruposStatusPorPalavra', providerField: 'GRUPOS_STATUS.GRUPO_STATUS', searchField: 'PALAVRA' }),
    statusGroup: new Entity({ fieldNameId: 'ID_STATUS', descriptionField: 'DESC_STATUS', commandName: 'ConsultarStatus', providerField: 'STATUS_GRUPO.STATUS', searchField: 'PALAVRA' }),
    providerByWord: new Entity({ fieldNameId: 'ID_FORNECEDOR', descriptionField: 'NOME_COMPLETO', commandName: 'ConsultarFornecedoresPorPalavra', providerField: 'FORNECEDORES.FORNECEDOR', searchField: 'PALAVRA', params: { ATIVO: 1 }, editDialog: { page: { name: 'Person' }, pageConfig: { isDialog: true, dialog: { fullscreen: false } }, params: { ID_FORNECEDOR: '_personId' } } }),
    technicalScope: new Entity({ fieldNameId: 'ID_COMPETENCIA_TECNICA', descriptionField: 'DESC_COMPETENCIA_TECNICA', commandName: 'ConsultarCompetenciaTecnicaPorPalavra', providerField: 'COMPETENCIAS_TECNICAS.COMPETENCIA_TECNICA', searchField: 'PALAVRA' }),
    issueTypeOrigin: new Entity({ fieldNameId: 'ID_TIPO_ORIGEM_OCORRENCIA', descriptionField: 'DESC_TIPO_ORIGEM_OCORRENCIA', commandName: 'ConsultarTipoOrigemOcorrencia', providerField: 'TIPOS_ORIGEM_OCORRENCIA.TIPO_ORIGEM_OCORRENCIA' }),
    satisfactionSurveyTypeOrigin: new Entity({ fieldNameId: 'ID_TIPO_ORIGEM_PESQUISA_SATISFACAO', descriptionField: 'DESC_TIPO_ORIGEM_PESQUISA_SATISFACAO', commandName: 'ConsultarTipoOrigemPesquisaSatisfacao', providerField: 'TIPOS_ORIGEM_PESQUISA_SATISFACAO.TIPO_ORIGEM_PESQUISA_SATISFACAO' }),
    superModule: new Entity({ fieldNameId: 'ID_SUPER_MODULO', descriptionField: 'NOME_SUPER_MODULO', commandName: 'ConsultarSuperModulos', providerField: 'SUPER_MODULOS.SUPER_MODULO', searchField: 'PALAVRA' }),
    projectStudy: new Entity({ fieldNameId: 'ID_ESTUDO_PROJETO', descriptionField: 'DESC_ESTUDO_PROJETO', commandName: 'ConsultarEstudoProjetoPorPalavra', providerField: 'ESTUDOS_PROJETO.ESTUDO_PROJETO', searchField: 'PALAVRA' }),
    qrCodeModel: new Entity({ fieldNameId: 'ID_MODELO_QRCODE', descriptionField: 'DESC_MODELO', commandName: 'ConsultarModeloQRCode', providerField: 'MODELOS_QRCODE.MODELO_QRCODE', searchField: 'DESC_MODELO' }),
    projectServiceOrder: new Entity({ fieldNameId: 'ID_ORDEM_SERVICO', descriptionField: 'DESCRICAO', commandName: 'ConsultarOSObra', providerField: 'OSS.OS' }),
    photoType: new Entity({ fieldNameId: 'ID_TIPO_FOTO', descriptionField: 'DESCRICAO', commandName: 'ConsultarTipoFoto', providerField: 'TIPOS_FOTO.TIPO_FOTO', editDialog: { page: { name: 'PhotoTemplatePhotoType' }, pageConfig: { isDialog: true, dialog: { fullscreen: false } }, params: { ID_TIPO_FOTO: '_photoTypeId' } } }),
    manufacturers: new Entity({ fieldNameId: 'ID_FABRICANTE', descriptionField: 'NOME', commandName: 'ConsultarFabricantesPorPalavra', providerField: 'FABRICANTES.FABRICANTE', searchField: 'PALAVRA' }),
    publicPlace: new Entity({ fieldNameId: 'ID_LOGRADOURO', descriptionField: 'NOME_LOGRADOURO', commandName: 'ConsultarLogradouroPorPalavra', providerField: 'LOGRADOUROS.LOGRADOURO', searchField: 'PALAVRA', params: { PAGE_SIZE: 300 }, minToSearch: 2 }),
    activityType: new Entity({ fieldNameId: 'ID_TIPO_ATIVIDADE', descriptionField: 'DESC_TIPO_ATIVIDADE', commandName: 'ConsultarTipoAtividadePorPalavra', providerField: 'TIPO_ATIVIDADES.TIPO_ATIVIDADE', searchField: 'PALAVRA' }),
    competitor: new Entity({ fieldNameId: 'ID_CONCORRENTE', descriptionField: 'NOME_COMPLETO', commandName: 'ConsultarConcorrentesPorPalavra', providerField: 'CONCORRENTES.CONCORRENTE', searchField: 'PALAVRA' }),
    shippingCompanies: new Entity({ fieldNameId: 'ID_TRANSPORTADORA', descriptionField: 'NOME', commandName: 'ConsultarTransportadorasPorPalavra', providerField: 'TRANSPORTADORAS.TRANSPORTADORA', searchField: 'PALAVRA' }),
    financialCategories: new Entity({ fieldNameId: 'ID_CATEGORIA', descriptionField: 'DESC_CATEGORIA', commandName: 'ConsultarCategoriasFinanceirasFilhoPorPalavra', providerField: 'CATEGORIAS.CATEGORIA', searchField: 'PALAVRA' }),
    assetsStructures: new Entity({ fieldNameId: 'ID_ESTRUTURA_PS', descriptionField: 'DESC_ESTRUTURA_PS', commandName: 'ConsultarEstruturasPontoServico', providerField: 'ESTRUTURAS_PONTO_SERVICO.ESTRUTURA_PONTO_SERVICO', searchField: 'PALAVRA' }),
    projectType: new Entity({ fieldNameId: 'ID_MODAL_OBRA', descriptionField: 'DESC_MODAL_OBRA', commandName: 'ConsultarModalidadeObraPorPalavra', providerField: 'MODALIDADES_OBRA.MODALIDADE_OBRA', searchField: 'PALAVRA' }),
    cfop: new Entity({ fieldNameId: 'ID_CFOP', descriptionField: 'ID_NATUREZA_OPERACAO_CFOP', commandName: 'ConsultarCFOPNFEntrada', providerField: 'CFOPS.CFOP', searchField: 'PALAVRA' }),
    teamSupervisors: new Entity({
        fieldNameId: 'ID_SUPERVISOR_EQUIPE',
        descriptionField: 'NOME_SUPERVISOR_EQUIPE',
        commandName: 'ConsultarSupervisoresEquipe',
        providerField: 'SUPERVISORES.SUPERVISOR',
    }),
    childrenFinancialCategories: new Entity({ fieldNameId: 'ID_CATEGORIA', descriptionField: 'DESC_CATEGORIA', commandName: 'ConsultarCategoriasFinanceirasFilhoPorPalavra', providerField: 'CATEGORIAS.CATEGORIA', searchField: 'PALAVRA', minToSearch: 2 }),
    tables: new Entity({ fieldNameId: 'TABLE_NAME', descriptionField: 'TABLE_NAME', commandName: 'ConsultarTabelasSistema', providerField: 'TABLES.TABLE', searchField: 'PALAVRA', params: { TABELAS_LIVRE: 1 } }),
    parentDocumentModel: new Entity({ fieldNameId: 'ID_MODELO_DOCUMENTO', descriptionField: 'COD_MODELO_DOCUMENTO', commandName: 'ConsultarModeloDocumentoPaiPorPalavra', providerField: 'MODELOS_DOCUMENTOS.MODELO_DOCUMENTO', searchField: 'PALAVRA' }),
    workDepartmentByWord: new Entity({ fieldNameId: 'ID_DEPTO_EMPRESA', descriptionField: 'COD_DEPTO_EMPRESA', commandName: 'ConsultarDeptoEmpresaPaiPorPalavraSrv', providerField: 'DEPTOS_EMPRESA.DEPTO_EMPRESA', searchField: 'PALAVRA', minToSearch: 3 }),
    equipmentVehicles: new Entity({ fieldNameId: 'ID_EQUIPAMENTO', descriptionField: 'LABEL_EQUIPAMENTO', commandName: 'ConsultarEquipamentosVeiculos', providerField: 'EQUIPAMENTOS.EQUIPAMENTO', searchField: 'PALAVRA' }),
    script: new Entity({ fieldNameId: 'ID_SCRIPT', descriptionField: 'NAME', commandName: 'ConsultarScripts', providerField: 'SCRIPTS.SCRIPT', searchField: 'NOME' }),
    importType: new Entity({ fieldNameId: 'ID_TIPO_IMPORTACAO', descriptionField: 'DESC_TIPO_IMPORTACAO', commandName: 'ConsultarTipoImportacao', providerField: 'TIPOS_IMPORTACAO.TIPO_IMPORTACAO', searchField: 'PALAVRA', params: { entidade: 'OBRA' } }),
    cnae: new Entity({ fieldNameId: 'COD_CNAE', descriptionField: 'DESCRICAO', commandName: 'ConsultarCNAEPorPalavra', providerField: 'CNAES.CNAE', searchField: 'PALAVRA' }),
    billSaleServices: new Entity({ fieldNameId: 'ID_SERVICO_NFS', descriptionField: 'DESCRICAO', commandName: 'ConsultarListaServicosNFS', providerField: 'SERVICOS_NFS.SERVICO_NFS', searchField: 'BUSCA' }),
    closureType: new Entity({ fieldNameId: 'ID_MOD_FECHAMENTO_EMP_DEV_MAT', descriptionField: 'DESC_MOD_FECHAMENTO_EMP_DEV_MAT', commandName: 'ConsultarModalFechamentoEmpDevMat', providerField: 'MODALIDADES_FECHAMENTO.MODALIDADE_FECHAMENTO', searchField: 'PALAVRA', params: { COM_OBRA: 1, AUTOMATICO: 0 } }),
    customReportColumns: new Entity({ fieldNameId: 'ID_COLUMN_RELATORIO_CUSTOMIZADO', descriptionField: 'COLUMN_NAME', commandName: 'ConsultarColumnsRelatorioCustomizado', providerField: 'COLUMNS_RELATORIO_CUSTOMIZADO.COLUMN_RELATORIO_CUSTOMIZADO', searchField: 'PALAVRA' }),
    library: new Entity({ fieldNameId: 'ID_BIBLIOTECA', descriptionField: 'COD_BIBLIOTECA', commandName: 'ConsultarBibliotecas', providerField: 'BIBLIOTECAS.BIBLIOTECA', searchField: 'PALAVRA' }),
    importer: new Entity({ fieldNameId: 'ID_IMPORTADOR', descriptionField: 'DESC_IMPORTADOR', commandName: 'ConsultarImportador', providerField: 'IMPORTADORES.IMPORTADOR' }),
    userCompanyNoSave: new Entity({ fieldNameId: 'ID_PESSOA', descriptionField: 'NOME', commandName: 'ConsultarEmpresasUsuariasPorPalavra', providerField: 'PESSOAS.PESSOA', searchField: 'PALAVRA', editDialog: { page: { name: 'UserCompany' }, pageConfig: { isDialog: true, dialog: { fullscreen: false } }, params: { ID_PESSOA: '_userCompanyId' } }, minToSearch: 2 }),
    financialScheduleId: new Entity({ fieldNameId: 'ID_AGEND_FINANC', descriptionField: 'ID_AGEND_FINANC', commandName: 'ConsultarIdsAgendamentosFinanceiros', providerField: 'AGENDAMENTOS.AGENDAMENTO', searchField: 'ID_AGENDAMENTO_FINANCEIRO' }),
    deadlineWindowsScheme: new Entity({ fieldNameId: 'ID_ESQUEMA_JANELAS_PRAZO', descriptionField: 'DESC_ESQUEMA_JANELAS_PRAZO', commandName: 'ConsultarEsquemaJanelasPrazo', providerField: 'ESQUEMAS_JANELAS_PRAZO.ESQUEMA_JANELAS_PRAZO' }),
    thematicMap: new Entity({ fieldNameId: 'ID_MAPA_TEMATICO', descriptionField: 'DESC_MAPA_TEMATICO', commandName: 'ConsultarMapasTematicos', providerField: 'MAPAS_TEMATICOS.MAPA_TEMATICO', editDialog: { page: { name: 'ThematicMap' }, params: { ID_MAPA_TEMATICO: '_thematicMapId' } } }),
    complainer: new Entity({ fieldNameId: 'ID_RECLAMANTE', descriptionField: 'ID_E_NOME', commandName: 'ConsultarDadosReclamantePorPalavra', providerField: 'RECLAMANTES.RECLAMANTE', searchField: 'PALAVRA', minToSearch: 3 }),
    serviceList: new Entity({ fieldNameId: 'ID_ITEM', descriptionField: 'DESC_ITEM', commandName: 'ConsultarItens', providerField: 'ITENS.ITEM', searchField: 'PALAVRA', minToSearch: 3 }),
    services: new Entity({ fieldNameId: 'ID_SERVICO', descriptionField: 'DESC_SERVICO', commandName: 'ConsultarServicoPorPalavra', providerField: 'SERVICOS.SERVICO', searchField: 'PALAVRA', minToSearch: 3 }),
    mapCaption: new Entity({ fieldNameId: 'ID_CONFIG_LEGENDA_MAPA', descriptionField: 'NOME_CONFIG_LEGENDA_MAPA', commandName: 'ConsultarConfigLegendaMapa', providerField: 'CONFIGS_LEGENDAS_MAPAS.CONFIG_LEGENDA_MAPA', editDialog: { page: { name: 'ProjectStructureMapCaption' }, params: { ID_CONFIG_LEGENDA_MAPA: '_mapCaptionConfigId' }, pageConfig: { isDialog: true, dialog: { fullscreen: false, width: '1000' } } } }),
    mapCaptionSimple: new Entity({ fieldNameId: 'ID_CONFIG_LEGENDA_MAPA', descriptionField: 'NOME_CONFIG_LEGENDA_MAPA', commandName: 'ConsultarConfigLegendaMapa', providerField: 'CONFIGS_LEGENDAS_MAPAS.CONFIG_LEGENDA_MAPA', params: { SIMPLES: 1 }, editDialog: { page: { name: 'ProjectStructureAsBuiltCaption' }, params: { ID_CONFIG_LEGENDA_MAPA: '_mapCaptionConfigId' }, pageConfig: { isDialog: true, dialog: { fullscreen: false } } } }),
    trackerList: new Entity({ fieldNameId: 'ID_RASTREADOR', descriptionField: 'NOME_RASTREADOR', commandName: 'ConsultarRastreador', providerField: 'RASTREADORES.RASTREADOR', searchField: 'BUSCA', minToSearch: 2 }),
    finantialCategoryById: new Entity({ fieldNameId: 'ID_CATEGORIA', descriptionField: 'COD_CATEGORIA', commandName: 'ConsultarCategoriaFinanceiraPaiPorPalavra', providerField: 'CATEGORIAS.CATEGORIA', searchField: 'PALAVRA', minToSearch: 2 }),
    country: new Entity({ fieldNameId: 'ID_PAIS', descriptionField: 'NOME', commandName: 'ConsultarPaises', providerField: 'PAISES.PAIS' }),
    trackerDevices: new Entity({ fieldNameId: 'ID_DEVICE', descriptionField: 'NOME', commandName: 'ConsultarDevicesRastreamento', providerField: 'DEVICES.DEVICE' }),
    materialsByWord: new Entity({ fieldNameId: 'ID_ITEM', descriptionField: 'DESC_ITEM', commandName: 'ConsultarMateriaisServicosPorPalavra', providerField: 'ITENS.ITEM' }),
    versionClientApp: new Entity({ fieldNameId: 'ID_VERSAO_APLICATIVO', descriptionField: 'DESC_VERSAO_APLICATIVO_ANDROID', commandName: 'ConsultarVersoesAplicativoAndroid', providerField: 'VERSOES.VERSAO' }),
    allUsers: new Entity({ fieldNameId: 'ID_PESSOA', descriptionField: 'NOME', commandName: 'ConsultarUsuarioPorPalavra', providerField: 'USUARIOS.USUARIO', searchField: 'PALAVRA' }),
    maintenanceItens: new Entity({ fieldNameId: 'ID_ITEM', descriptionField: 'DESC_ITEM', commandName: 'ConsultarItens', providerField: 'ITENS.ITEM', params: { ATIVO: 1 } }),
    identityType: new Entity({ fieldNameId: 'ID_TIPO_IDENTIDADE', descriptionField: 'COD_DOCUMENTO', commandName: 'ConsultarTipoIdentidade', providerField: 'TIPOS_IDENTIDADE.TIPO_IDENTIDADE' }),
    localTypeEnergyConsumption: new Entity({ fieldNameId: 'ID_TIPO_LOCAL_CONSUMO_ENERGETICO', descriptionField: 'DESCRICAO', commandName: 'ConsultarTipoLocalConsumoEnergetico', providerField: 'TIPOS_LOCAIS_CONSUMO_ENERGETICO.TIPO_LOCAL_CONSUMO_ENERGETICO' }),
    processTypes: new Entity({ fieldNameId: 'KEY_PROCESSO', descriptionField: 'DESC_TIPO_PROCESSO', commandName: 'ConsultarTipoProcessoCamunda', providerField: 'TIPOS_PROCESSO.TIPO_PROCESSO' }),
    processTypesInboundEndpoint: new Entity({ fieldNameId: 'INBOUND_ENDPOINTS', descriptionField: 'DESC_TIPO_PROCESSO', commandName: 'ConsultarInboundEndpoint', providerField: 'TIPOS_PROCESSO.TIPO_PROCESSO' }),
    countyByState: new Entity({ fieldNameId: 'ID_MUNICIPIO', descriptionField: 'NOME_MUNICIPIO', commandName: 'BuscaMunicipiosPorUF', providerField: 'MUNICIPIOS.MUNICIPIO', params: {} }),
    stockCenterFinancialCenter: new Entity({ fieldNameId: 'ID_CENTRO_FINANC', descriptionField: 'COD_CENTRO_FINANC', commandName: 'ConsultarCentroFinanceiroCentroEstoque', providerField: 'CENTROS_FINANCS.CENTRO_FINANC' }),
    worksiteFamilies: new Entity({ fieldNameId: 'ID_FAMILIA', descriptionField: 'DESC_FAMILIA', commandName: 'ConsultarFamiliasParqueServico', providerField: 'FAMILIAS.FAMILIA' }),
    attributes: new Entity({ fieldNameId: 'ID_ATRIBUTO', descriptionField: 'NOME', commandName: 'ConsultarAtributos', providerField: 'ATRIBUTOS.ATRIBUTO' }),
    familiesCOD2: new Entity({ fieldNameId: 'ID_FAMILIA', descriptionField: 'COD_FAMILIA', commandName: 'ConsultarFamilias', providerField: 'FAMILIAS.FAMILIA', params: { CONSUMO: 0 }, minToSearch: 2 }),
    interventionFamilies2: new Entity({ fieldNameId: 'ID_FAMILIA', descriptionField: 'DESC_FAMILIA', commandName: 'ConsultarFamiliasPorPalavra', providerField: 'FAMILIAS.FAMILIA', params: { CONSUMO: 0 } }),
    iotFunctionType: new Entity({ fieldNameId: 'ID_FUNCTION_TYPE', descriptionField: 'NAME', commandName: 'ConsultarTiposFuncaoIoTHub', providerField: 'TIPOS_FUNCAO.TIPO_FUNCAO' }),
    deviceClass: new Entity({ fieldNameId: 'UUID', descriptionField: 'NAME', commandName: 'ConsultarClassesDispositivosIoTHub', providerField: 'CLASSES.CLASSE' }),
    iotAttributes: new Entity({ descriptionField: 'NAME', commandName: 'ConsultarAtributosIoTHub', providerField: 'ATRIBUTOS.ATRIBUTO' }),
};

export default defaultProvider;
